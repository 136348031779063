import React, { useState, useEffect } from 'react';
import { SafeAreaView, Image, StyleSheet, View, Text, ScrollView, TouchableOpacity, Clipboard, TextInput, Button, Alert, Picker, Linking } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import QRCode from 'react-native-qrcode-svg';


const CollabCampaignDetails = ({ route, navigation }) => {
  const { CollabID } = route.params;
  const [secretQRID, setSecretQRID] = useState('');
  const [ipCount, setIpCount] = useState(null);
  const [tierData, setTierData] = useState(null);
  const [redeemableTier, setRedeemableTier] = useState('None');
  const [redeemableValue, setRedeemableValue] = useState(0);
  const [redeemableOffer, setRedeemableOffer] = useState(0);
  const [userData, setUserData] = useState({});
  const [promoterEmail, setPromoterEmail] = useState(null); 
  const [promoterName, setPromoterName] = useState(null);
  const [message, setMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [latestUpdate, setLatestUpdate] = useState({ Message: '', CreditAvailableStart: '', Status: '' });
  const [mediaContent, setMediaContent] = useState([]);
  const [apiMessages, setApiMessages] = useState([]);
  const [isApproved, setIsApproved] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [platformUsername, setPlatformUsername] = useState('');
  const [platformFollowers, setPlatformFollowers] = useState('');
  const [isRedeemed, setIsRedeemed] = useState(false);
  const [redeemDateTime, setRedeemDateTime] = useState(null);
  const [selectedTime, setSelectedTime] = useState('7:00 AM');

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [cancelMessage, setCancelMessage] = useState('');

  const [isExpanded, setIsExpanded] = useState(false);

const handleToggleExpand = () => {
  setIsExpanded(!isExpanded);
};




  useEffect(() => {
    fetchSecretQRID();
    fetchNewApi();
    fetchUserData();
    fetchPromoterDetails(); // Updated function name
    fetchLatestCollabUpdate();
    fetchMediaContent();
    fetchUserMediaValidation();
    fetchCreditAvailabilityValidation();
    fetchBrandApprovalValidation();
    checkRedeemStatus(); 
    
  }, []);



  const fetchPromoterDetails = () => {
    fetch(`https://collabbayadmin.com/APIControllers/GetCollabPromoterName.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data && data[0] && data[0].Promoter) {
          const fetchedPromoter = data[0].Promoter;
          setPromoterName(fetchedPromoter);
          fetchPromoterEmail(fetchedPromoter);
          fetchPromoterProfile(fetchedPromoter); // Fetch the promoter's profile details
        } else {
          console.error('Promoter name not found');
        }
      })
      .catch(error => console.error('Error fetching promoter name:', error));
  };
  

  
  const fetchPromoterProfile = (promoterName) => {
    fetch(`https://collabbayadmin.com/APIControllers/ShowPromoterProfile.php?Promoter=${promoterName}`)
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data) && data.length > 0) {
          const profileData = data[0];
          setProfileImage(profileData.ProfileImage);
          setPlatformUsername(profileData.PlatformUsername);  // Set platform username dynamically
          setPlatformFollowers(profileData.PlatformFollowers);
        } else {
          console.error('Promoter profile data not found');
        }
      })
      .catch(error => console.error('Error fetching promoter profile:', error));
  };
  

  const handleCancelBooking = () => {
    const fullDateTime = new Date().toISOString(); // Replace this with actual date-time if needed
    
    fetch(`https://collabbayadmin.com/APIControllers/UserCancelCollab.php?CollabID=${CollabID}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Message: cancelMessage,
        CreditAvailableStart: fullDateTime,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('Cancellation Sent');
          setIsCancelModalVisible(false); // Close the modal
          navigation.goBack(); // Navigate back or refresh as necessary
        } else {
          alert('There was an error processing your cancellation.');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('An unexpected error occurred.');
      });
  };
  
  const toggleCancelModal = () => {
    setIsCancelModalVisible(!isCancelModalVisible);
  };
  

  const fetchPromoterEmail = (promoterName) => { // Updated function
    fetch(`https://collabbayadmin.com/APIControllers/GetCollabPromoterEmail.php?Promoter=${promoterName}`)
      .then(response => response.json())
      .then(data => {
        if (data && data[0] && data[0].UserEmail) {
          setPromoterEmail(data[0].UserEmail);
        } else {
          console.error('Promoter email not found');
        }
      })
      .catch(error => console.error('Error fetching promoter email:', error));
  };

  const fetchSecretQRID = () => {
    fetch(`https://collabbay.com/APIControllers/RetrieveQRSecretForScan.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data.SecretQRID) {
          setSecretQRID(data.SecretQRID);
        } else {
          console.error('SecretQRID not found');
        }
      })
      .catch(error => console.error('Error fetching SecretQRID:', error));
  };

  const fetchNewApi = () => {
    fetch(`https://collabbay.com/APIControllers/RetrieveCollabViewCount.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(responseData => {
        setIpCount(parseInt(responseData.ipCount));
        fetchTierAssignApi();
      })
      .catch(error => {
        console.error('Error fetching IP count:', error);
      });
  };

  const fetchTierAssignApi = () => {
    fetch(`https://collabbay.com/APIControllers/RetrieveCollabTierData.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(responseData => {
        setTierData(responseData);
      })
      .catch(error => {
        console.error('Error fetching tier data:', error);
      });  
  };

  const fetchUserData = () => {
    fetch(`https://collabbay.com/APIControllers/GetSessionData.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        setUserData(data);
      })
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const fetchLatestCollabUpdate = () => {
    fetch(`https://collabbay.com/APIControllers/GetLatestCollabUpdate.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setLatestUpdate({
            Message: data.Message || '',
            CreditAvailableStart: data.CreditAvailableStart || '',
            Status: data.Status || '',
            Credits: data.Credits || '',
            BrandName: data.BrandName || '',
            AddedVisitors: data.AddedVisitors || 0,  // Add this line to capture AddedVisitors
          });
        } else {
          console.error('Latest collab update not found');
        }
      })
      .catch(error => {
        console.error('Error fetching latest collab update:', error);
      });
  };

  const fetchMediaContent = () => {
    fetch(`https://collabbayadmin.com/APIControllers/GetCollabMedia.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
          setMediaContent(data);
        } else {
          console.log('No media content found');F
        }
      })
      .catch(error => {
        console.error('Error fetching media content:', error);
      });
  };
  
  const fetchUserMediaValidation = async () => {
    try {
        const response = await fetch(`https://collabbayadmin.com/APIControllers/ErrorCheckUserMedia.php?CollabID=${CollabID}`);
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        const result = await response.json(); // Parse as JSON

        const message = result.message === 'Not Validated' 
            ? "Warning, Promoter has not uploaded their post - credits Unredeemable" 
            : "User content uploaded";
        
        setApiMessages(prev => [...prev, message]);
    } catch (error) {
        console.error('Error fetching user media validation:', error);
    }
};

const fetchCreditAvailabilityValidation = async () => {
  try {
      const response = await fetch(`https://collabbayadmin.com/APIControllers/ErrorCheckCreditAvailability.php?CollabID=${CollabID}`);
      
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      
      const result = await response.json(); // Parse as JSON

      const message = result.message === 'Not Validated' 
          ? "Warning, Credits expired or not yet available, check the start date" 
          : "Within credit time availability ";
      
      setApiMessages(prev => [...prev, message]);
  } catch (error) {
      console.error('Error fetching credit availability validation:', error);
  }
};



  const fetchBrandApprovalValidation = async () => {
    try {
        const response = await fetch(`https://collabbayadmin.com/APIControllers/ErrorCheckBrandApproval.php?CollabID=${CollabID}`);
        const result = await response.json(); // Parse as JSON

        const message = result.message === 'Not Validated' 
            ? "Warning, approve collab to unlock promoters credits"
            : "Collab approved";
        
        setApiMessages(prev => [...prev, message]);
    } catch (error) {
        console.error('Error fetching brand approval validation:', error);
    }
};

const handleApproveCollab = () => {
  fetch(`https://collabbayadmin.com/APIControllers/AdminApproveCollab.php?CollabID=${CollabID}`, {
    method: 'GET', // Changed from POST to GET
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(data => {
      if (data.message === "Collab updated successfully.") {
        setIsApproved(true); // Update approval status
        window.location.href = 'https://collabbayadmin.com/'; // Redirect to external URL
      } else {
        Alert.alert('Error', data.message || 'Failed to approve collaboration.');
      }
    })
    .catch(error => {
      console.error('Error:', error);
      Alert.alert('Error', 'An error occurred while approving collaboration.');
    });
};



const checkRedeemStatus = () => {
  fetch(`https://collabbayadmin.com/APIControllers/CheckCollabRedeemedStatus.php?CollabID=${CollabID}`)
    .then(response => response.json())
    .then(data => {
      if (data.message === "Redeem Found") {
        setIsRedeemed(true);
        setRedeemDateTime(data.dateTime); // Store the DateTime
      }
    })
    .catch(error => console.error('Error fetching redeem status:', error));
};

const renderRedeemStatus = () => {
  if (isRedeemed) {
    return (
      <View style={styles.redeemedContainer}>
        <Text style={styles.redeemedText}>Redeemed on {redeemDateTime}</Text> {/* Display the DateTime */}
      </View>
    );
  }
  return null;
};

  useEffect(() => {
    calculateRedeemableValues();
  }, [ipCount, tierData]);

  const calculateRedeemableValues = () => {
    if (ipCount !== null && tierData !== null) {
      if (ipCount >= 0 && ipCount < tierData.TierOneTarget) {
        setRedeemableTier('None');
        setRedeemableValue(0);
        setRedeemableOffer(0);
      } else if (ipCount >= tierData.TierOneTarget && ipCount < tierData.TierTwoTarget) {
        setRedeemableTier('1');
        setRedeemableValue(tierData.TierOneTarget);
        setRedeemableOffer(tierData.TierOneOffer);
      } else if (ipCount >= tierData.TierTwoTarget && ipCount < tierData.TierThreeTarget) {
        setRedeemableTier('2');
        setRedeemableValue(tierData.TierTwoTarget);
        setRedeemableOffer(tierData.TierTwoOffer);
      } else if (ipCount >= tierData.TierThreeTarget) {  
        setRedeemableTier('3');
        setRedeemableValue(tierData.TierThreeTarget);
        setRedeemableOffer(tierData.TierThreeOffer);
      }
    }
  };

  const handleCopyURL = () => {
    Clipboard.setString(`https://collabbay.com/collab/${CollabID}`);
    alert('URL copied to clipboard!');
  };

  const handleQRCodePress = () => {
    Linking.openURL('https://collabbayadmin.com/QR.html');
  };

  const handleBackPress = () => {
    navigation.goBack();
  };


  const renderDatePicker = () => {
    const dates = [];
    const today = new Date();

    for (let i = 0; i < 30; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);
      dates.push(date.toDateString());
    }

    return (
      <View style={styles.datePickerContainer}>
        <Text style={styles.datePickerLabel}>Move Collab Date?</Text>
        <ScrollView horizontal>
          {dates.map((date, index) => (
            <TouchableOpacity
              key={index}
              style={[styles.dateOption, { backgroundColor: selectedDate === date ? '#FDF05D' : 'white' }]}
              onPress={() => setSelectedDate(date)}
            >
              <Text style={styles.dateOptionText}>{date}</Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
    );
  };

  const renderTimePicker = () => {
    const timeSlots = [];
    for (let i = 14; i < 40; i++) { // Generates times starting from 7:00 AM (14th half-hour slot)
      const hour = Math.floor((i + 14) / 2) % 24; // Adjust for a 24-hour clock
      const minute = i % 2 === 0 ? '00' : '30';
      const ampm = hour >= 12 ? 'PM' : 'AM';
      const adjustedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert to 12-hour clock format
      const timeLabel = `${adjustedHour}:${minute} ${ampm}`;
      timeSlots.push(timeLabel);
    }
  
    return (
      <View style={styles.timePickerContainer}>
        <Text style={styles.timePickerLabel}>Select Time</Text>
        <ScrollView style={styles.timeScrollView}>
          {timeSlots.map((time, index) => (
            <TouchableOpacity
              key={index}
              style={[
                styles.timeSlot,
                selectedTime === time && styles.selectedTimeSlot
              ]}
              onPress={() => setSelectedTime(time)}
            >
              <Text>{time}</Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
    );
  };



  const handleSubmit = () => {
    const fullDateTime = `${selectedDate} ${selectedTime}`;
  
    fetch(`https://collabbayadmin.com/APIControllers/AdminUpdateCollab.php?CollabID=${CollabID}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Message: message,
        CreditAvailableStart: fullDateTime,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('Update submitted');
          window.location.reload(); // Refresh the app to reflect changes
        } else {
          Alert.alert('Error', 'There was an error updating your collab details.');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  

  const renderMediaContent = () => {
    return (
      <View style={styles.mediaContentSection}>
        <Text style={styles.mediaContentHeader}>Content</Text>
        {mediaContent.map((item, index) => (
          <View key={index} style={styles.mediaItemContainer}>
            <Image
              style={styles.mediaImage}
              source={{ uri: item.Image }}
              resizeMode="cover"
            />
            <View style={styles.mediaContentTextSection}>
              <Text style={styles.mediaPlatformText}>
                {item.PostPlatform}
              </Text>
              
              <TouchableOpacity
  onPress={() => {
    const formattedURL = item.PostURL.startsWith('http')
      ? item.PostURL
      : `https://${item.PostURL}`;
    Linking.openURL(formattedURL);
  }}
>
  <Text style={styles.mediaURLText}>
    <Ionicons name="link" size={20} color="blue" />
    {item.PostURL}
  </Text>
</TouchableOpacity>



              <Text style={styles.mediaMessageText}>
                <Ionicons name="chatbubble-ellipses" size={20} color="black" /> {item.Message}
              </Text>
            </View>
          </View>
        ))}
      </View>
    );
  };

  const handleMediaItemClick = (url) => {
    Linking.openURL(url);
  };



  const renderPromoterProfile = () => {
    const handlePress = () => {
      const url = `https://www.instagram.com/${platformUsername}`;
      Linking.openURL(url).catch((err) => console.error("Failed to open URL:", err));
    };
  
    return (
      <View style={styles.profileCard}>
        <Image
          style={styles.profileImage}
          source={{ uri: profileImage }}
        />
        <Text style={styles.profileUsername}>{platformUsername}</Text>
        <Text style={styles.profileFollowers}>{platformFollowers} Followers</Text>
        <TouchableOpacity onPress={handlePress}>
          <Ionicons name="link" size={24} color="black" />
        </TouchableOpacity>
      </View>
    );
  };
  

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
      <View style={styles.backButtonContainer}>
          <TouchableOpacity onPress={handleBackPress}>
            <Ionicons name="arrow-back" size={24} color="black" />
          </TouchableOpacity>
        </View>

        <View style={{
      backgroundColor: '#f2f2ee',
      borderRadius: 10,
      padding: 20,
      shadowOpacity: 0.1,
      shadowOffset: { width: 0, height: 2 },
      shadowRadius: 5,
      elevation: 3,
      marginBottom: 20,
      width: '90%',
      alignSelf: 'center'
    }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
        <Text style={{ fontSize: 18, fontWeight: 'bold', color: '#333' }}>How to collab</Text>
        <Ionicons name="help-circle-outline" size={24} color="black" />
      </View>
      <View style={{ marginTop: 10 }}>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>1-</Text> The collabbay creator will enter the venue
        </Text>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>2-</Text> Provide the offer stated on the collab
        </Text>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>3-</Text> The influencer will create content, post, and upload it
        </Text>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>4-</Text> When it’s time to pay, scan the influencer’s QR, logging the offer as redeemed
        </Text>
      </View>
    </View>


        <Text style={styles.header}>Collab Campaign Details</Text>
        <TouchableOpacity style={styles.qrCodeContainer} onPress={handleQRCodePress}>
          <Ionicons name="scan" size={200} color="black" />
        </TouchableOpacity>

        {renderRedeemStatus()}

        <Text style={styles.scanCTALabel}>Scan Promoters QR to Redeem Collab Credits</Text>
   <Text style={styles.scanCTALabel}>Contact : {promoterEmail ? promoterEmail : ' '}</Text>


   <TouchableOpacity
  style={styles.influencerProfileButton}
  onPress={() => navigation.navigate('InfluencerProfileAnalysis', { PlatformUsername: platformUsername })}  // Pass dynamically fetched username
>
  <Text style={styles.influencerProfileButtonText}>
    View Influencer Profile Analysis
  </Text>
  <Ionicons name="arrow-forward" size={20} color="black" />
</TouchableOpacity>



<View style={styles.statusCard}>
  <Text style={styles.subheader}>Last Status</Text>
  <Text style={styles.statusLabel}>Message: {latestUpdate.Message}</Text>
  <Text style={styles.statusLabel}>Credit Available Start: {latestUpdate.CreditAvailableStart}</Text>
  <Text style={styles.statusLabel}>Status: {latestUpdate.Status}</Text>
  <Text style={styles.statusLabel}>Added Visitors (Plus-ones)?: {latestUpdate.AddedVisitors}</Text>  {/* Add this line */}
</View>

<Text style={styles.plusOneLabel}>Note | Via collabbay only the influencer can access this offer, their plus-ones are managed at your discretion.</Text>
      

        {renderPromoterProfile()} 

        {latestUpdate.Status !== 'Accepted' && (
  <TouchableOpacity style={styles.approveButton} onPress={handleApproveCollab}>
    <Ionicons name="checkmark-circle" size={20} color="black" />
    <Text style={styles.approveButtonText}>Approve</Text>
  </TouchableOpacity>
)}



        <TouchableOpacity style={styles.copyCollabButton} onPress={handleCopyURL}>
        <Text style={styles.copyButtonText}>Copy Collab URL <Ionicons name="copy-outline" size={16} /></Text>
        </TouchableOpacity>



        <View style={styles.apiMessagesContainer}>
      {apiMessages.map((msg, index) => (
        <View
          key={index}
          style={[
            styles.apiMessage,
            { backgroundColor: msg.includes('Warning') ? '#EC4646' : '#74dc8a' }
          ]}
        >
          <Ionicons
            name={msg.includes('Warning') ? 'alert-circle' : 'checkmark-circle'}
            size={24}
            color="white"
            style={styles.icon}
          />
          <Text style={styles.apiMessageText}>{msg}</Text>
        </View>
      ))}
    </View>


        <TouchableOpacity style={styles.header} onPress={handleToggleExpand}>
  <Text style={styles.updateSubheader}>Update, change or cancel collab booking?
  <Ionicons name={isExpanded ? 'chevron-up' : 'chevron-down'} size={24} color="black" />
  </Text>

</TouchableOpacity>
{isExpanded && (
  <View style={styles.updateCollabCard}>
    {renderDatePicker()}
    {renderTimePicker()}
    <TextInput
      style={styles.textInput}
      placeholder="Add a message?"
      value={message}
      onChangeText={setMessage}
    />
    <TouchableOpacity style={styles.button} onPress={handleSubmit}>
      <Text style={styles.buttonText}>Propose Update</Text>
    </TouchableOpacity>
  
  
  
    <TouchableOpacity  style={[styles.button, { backgroundColor: 'white' }]} onPress={toggleCancelModal}>
  <Ionicons name="warning-outline" size={20} color="red" />
  <Text style={{ color: 'red', textAlign: 'center'}}>Need to Cancel?</Text>
</TouchableOpacity>

{isCancelModalVisible && (
  <View style={styles.cancelModal}>
    <View style={styles.cancelModalContent}>
      <TouchableOpacity style={styles.closeButton} onPress={toggleCancelModal}>
        <Ionicons name="close-circle-outline" size={24} color="black" />
      </TouchableOpacity>
      <Text style={styles.cancelModalTitle}>Are you sure you want to cancel?</Text>
      <Text style={styles.cancelModalSubtitle}>Add a message (recommended):</Text>
      <TextInput
        style={styles.textInput}
        placeholder="Enter a message (optional)"
        value={cancelMessage}
        onChangeText={setCancelMessage}
      />
      <View style={styles.cancelModalButtons}>
        <TouchableOpacity style={{ padding: 10 }} onPress={handleCancelBooking}>
          <Text style={{ color: 'black', fontSize: 14, textAlign: 'center', fontWeight: 'bold' }}>
            Yes, Cancel Booking
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            backgroundColor: '#FDF05D',
            padding: 10,
            borderRadius: 20,
          }}
          onPress={toggleCancelModal}
        >
          <Text style={{ color: 'black', fontSize: 14, textAlign: 'center', fontWeight: 'bold' }}>
            No, Go Back
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  </View>
)}

  
  
  </View>
)}






        {renderMediaContent()}

      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: 'white',
    width: '95%',
    alignSelf: 'center', // Centers horizontally
    justifyContent: 'center', // Centers vertically
  },
  influencerProfileButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'black',
    paddingVertical: 12,
    marginVertical: 16,
    paddingHorizontal: 20,
  },
  influencerProfileButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
    marginRight: 8,
  },  
  contentContainer: {
    paddingBottom: 32,
  },
  backButtonContainer: {
    marginBottom: 16,
    padding: 16,
  },
  header: {
    fontSize: 24,
    marginBottom: 16,
    textAlign: 'center',
  },
  creditsHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    textAlign: 'center',
  },
  qrCodeContainer: {
    alignItems: 'center',
    marginBottom: 16,
  },
  label: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
  },
  datePickerContainer: {
    marginBottom: 20,
  },
  datePickerLabel: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
    textAlign: 'center',
  },
  dateOption: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginRight: 8,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'black',
  },
  dateOptionText: {
    fontSize: 16,
    color: 'black',
  },
  timePickerContainer: {
    marginBottom: 16,
  },
  timePickerLabel: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  pickerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  pickerLabel: {
    fontSize: 16,
    marginRight: 8,
    color: 'black',
  },
  picker: {
    flex: 1,
    color: 'black',
  },
  textInput: {
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 4,
    padding: 8,
    marginBottom: 16,
    fontSize: 16,
    color: 'black',
  },
  scanCTALabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'center',
  },
  statusCard: {
    backgroundColor: 'black',
    borderRadius: 8,
    padding: 16,
    marginVertical: 16,
    elevation: 2,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
  },
  updateCollabCard: {
    backgroundColor: '#F1F1F1',
    borderColor: 'black',
    padding: 16,
    marginVertical: 16,
    elevation: 2,
    shadowColor: 'black',
  },
  subheader: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
    color: 'white',
  },
  updateSubheader: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
    color: 'black',
    textAlign: 'center',
    marginLeft: 7,
    padding: 2,
     },
  copyCollabButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    paddingVertical: 12,
    marginBottom: 16,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    borderRadius: 20,
    paddingVertical: 12,
    marginBottom: 16,

  },
  buttonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  copyButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
  },
  statusLabel: {
    fontSize: 18,
    marginBottom: 8,
    color: 'white',
  },
  mediaContentSection: {
    marginTop: 16,
    paddingHorizontal: 8,
  },
  mediaContentHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    color: 'black',
    textAlign: 'center',
  },
  mediaItemContainer: {
    marginBottom: 24,
    borderRadius: 20,
    backgroundColor: '#FDF05D',
    padding: 16,
    elevation: 5,
  },
  mediaImage: {
    width: '100%',
    height: 500,
    borderRadius: 20,
    marginBottom: 16,
  },
  mediaContentTextSection: {
    padding: 16,
    borderRadius: 20,
    backgroundColor: 'white',
    textAlign: 'center',
  },
  mediaContentTextSection: {
    padding: 16,
    borderRadius: 20,
    backgroundColor: '#F0F0F0',
    textAlign: 'center',
  },
  mediaPlatformText: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
    textAlign: 'center',
  },
  mediaURLText: {
    fontSize: 16,
    color: 'blue',
    textDecorationLine: 'underline',
    marginBottom: 8,
    textAlign: 'center',
  },
  mediaMessageText: {
    fontSize: 16,
    color: 'black',
    textAlign: 'center',
  },
  apiMessagesContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  apiMessage: {
    borderRadius: 15,
    padding: 15,
    marginVertical: 5,
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  apiMessageText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    flex: 1,
  },
  icon: {
    marginRight: 10,
  },
  approveButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#74dc8a',
    borderRadius: 20,
    paddingVertical: 12,
    marginVertical: 16,
    flexDirection: 'row',
  },
  approveButtonText: {
    color: 'black',
  },
  profileCard: {
    backgroundColor: '#F1Fs1F1',
    padding: 16,
    marginVertical: 16,
    alignItems: 'center',
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginBottom: 8,
  },
  profileUsername: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'black',
    marginBottom: 4,
  },
  profileFollowers: {
    fontSize: 16,
    color: 'gray',
  },
  redeemedStatusCard: {
    backgroundColor: '#f8f8f8',
    borderRadius: 8,
    padding: 16,
    marginVertical: 16,
    elevation: 2,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
  },
  redeemedStatusHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
    color: 'black',
  },
  redeemedStatusItem: {
    padding: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  redeemContainer: {
    flex: 1,
    backgroundColor: '#fff',
  },
  scrollContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  redeemedContainer: {

    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  redeemedText: {
    fontSize: 16,
    color: '#000',
    textAlign: 'center',
  },
  timeScrollView: {
    height: 120, // Adjust as needed
  },
  timeSlot: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: '#eee',
  },
  selectedTimeSlot: {
    backgroundColor: '#FDF05D',
  },
  cancelModal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelModalContent: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    width: '90%',
    alignItems: 'center',
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
    elevation: 5,
  },
  cancelModalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#333',
    textAlign: 'center',
  },
  cancelModalSubtitle: {
    fontSize: 14,
    marginBottom: 10,
    color: '#666',
    textAlign: 'center',
  },
  cancelModalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: 16,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  plusOneLabel: {
    fontSize: 12,
    marginBottom: 5,
    marginTop: 5,
    textAlign: 'center',
  },
});

;
  
export default CollabCampaignDetails;

