import React, { useState } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Alert, ScrollView, Linking, SafeAreaView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

export default function App({ navigation }) {
  const [location, setLocation] = useState('');
  const [listingDescription, setListingDescription] = useState('');
  const [redeemMessage, setRedeemMessage] = useState('');
  const [adHeader, setAdHeader] = useState('');
  const [brandURL, setBrandURL] = useState('');
  const [credits, setCredits] = useState('');
  const [preferredAvailability, setPreferredAvailability] = useState('');
  const [contentBrief1, setContentBrief1] = useState('');
  const [contentBrief2, setContentBrief2] = useState('');
  const [contentBrief3, setContentBrief3] = useState('');
  const [nonCreditOffer, setNonCreditOffer] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);  // New state for the checkbox

  const handleBackPress = () => {
    navigation.goBack();
  };

  const handleSubmit = async () => {
    if (!termsAccepted) {
      Alert.alert('Terms of Use', 'You must agree to the terms of use before posting your offer.');
      return;
    }

    if (credits < 0 || credits > 10000) {
      Alert.alert('Invalid Credits', 'Credits must be between 0 and 10,000.');
      return;
    }

    if (nonCreditOffer < 0 || nonCreditOffer > 99) {
      Alert.alert('Invalid Non-Credit Offer', 'Please enter a 2-digit number for Non-Credit Offer.');
      return;
    }

    const contentBrief1Safe = contentBrief1.replace(/,/g, '');
    const contentBrief2Safe = contentBrief2.replace(/,/g, '');
    const contentBrief3Safe = contentBrief3.replace(/,/g, '');
    const contentBrief = [contentBrief1Safe, contentBrief2Safe, contentBrief3Safe]
      .filter(item => item.trim() !== '')
      .join(',');

    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/InsertCreatedBrandListing.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Location: location,
          ListingDescription: listingDescription,
          Redeem_Message: redeemMessage,
          AdHeader: adHeader,
          BrandURL: brandURL,
          Credits: credits,
          PreferredAvailability: preferredAvailability,
          ContentBrief: contentBrief,
          NonCreditOffer: nonCreditOffer
        }),
      });

      const result = await response.json();
      if (response.ok) {
        const listingID = result.ListingID;
        const url = `https://collabbayadmin.com/ListingImageUpload.html?ListingID=${encodeURIComponent(listingID)}`;
        Linking.openURL(url);
      } else {
        Alert.alert('Error', result.error);
      }
    } catch (error) {
      Alert.alert('Error', 'An error occurred while submitting the form.');
    }
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={styles.container}>
        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>

        <Text style={styles.header}>Post your offer to influencers</Text>
        <Text style={styles.subHeader}>Set up your collab bay with offer terms</Text>

        {/* Brand Details Section */}
        <Text style={styles.sectionHeader}>Brand Details</Text>
        <Text style={styles.sectionDescription}>Fill out your brand details, this will be shown on your offer.</Text>
        <View style={styles.inputContainer}>
          <Ionicons name="calendar-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Availability i.e. Mon - Fri 9AM - 8PM, Sat 10AM -10PM"
            placeholderTextColor="gray"
            value={preferredAvailability}
            onChangeText={setPreferredAvailability}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="location-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholderTextColor="gray"
            placeholder="i.e. City of London,  54 Example Road, W1R 3QL"
            value={location}
            onChangeText={setLocation}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="text-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={[styles.input, { height: 110 }]}
            placeholder="Talk about your brand, what you are offering, the creators you would like to visit, and anything else you want to mmention"
            value={listingDescription}
            placeholderTextColor="gray"
            onChangeText={setListingDescription}
            multiline={true}
          />
        </View>

        {/* Content Details Section */}
        <Text style={styles.sectionHeader}>Content Details</Text>
        <Text style={styles.sectionDescription}>Outline the content brief and guidelines for influencers so they know what to do when they visit.</Text>
        <View style={styles.inputContainer}>
          <Ionicons name="bulb-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholderTextColor="gray"
            placeholder="Brief Point 1 (i.e. 'Mention our new menu')"
            value={contentBrief1}
            onChangeText={(text) => setContentBrief1(text.replace(/,/g, ''))}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="bulb-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Brief Point 2 (i.e. 'Include cocktail in content')"
            value={contentBrief2}
            placeholderTextColor="gray"
            onChangeText={(text) => setContentBrief2(text.replace(/,/g, ''))}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="bulb-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholderTextColor="gray"
            placeholder="Brief Point 3 (i.e. 'Include view from our entrance')"
            value={contentBrief3}
            onChangeText={(text) => setContentBrief3(text.replace(/,/g, ''))}
          />
        </View>

        {/* Collab Offer Section */}
        <Text style={styles.sectionHeader}>Collab Offer</Text>
        <Text style={styles.sectionDescription}>Specify what you're offering to the influencers as part of this collab. If you only wish to specify a budget amount only (i.e. £40), leave the Offer blank and only add the value.</Text>
        <View style={styles.inputContainer}>
          <Ionicons name="people-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholderTextColor="gray"
            multiline={true}
            placeholder="Offer (where applicable) i.e. '2 x Cocktail Rounds and 1 Main'"
            value={nonCreditOffer}
            onChangeText={(text) => setNonCreditOffer(text)}
            keyboardType="default"
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="pricetag-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Value (i.e. £40)"
            value={credits}
            placeholderTextColor="gray"
            onChangeText={(text) => setCredits(text.replace(/[^0-9]/g, '').slice(0, 5))}
            keyboardType="numeric"
          />
        </View>

        {/* Link Details Section */}
        <Text style={styles.sectionHeader}>Link Details</Text>
        <Text style={styles.sectionDescription}>Each of your collabs comes with a link. This URL contains your brand info, your website, and any message you'd like to present to customers, essentially an advert promoters can distribute for you. When influencers share this, their audience see your website, message and offer. Want bookings? Put your number in the header, or maybe want to promote your instagram? Put your instagram page in the link section.</Text>
        <View style={styles.inputContainer}>
          <Ionicons name="newspaper-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={[styles.input, { height: 100 }]}
            placeholder="Ad Header (i.e. 'Come and visit us to experience our new menu! Click below for more')"
            value={adHeader}
            multiline={true}
            placeholderTextColor="gray"
            onChangeText={setAdHeader}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="link-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholderTextColor="gray"
            placeholder="yourwebsite.com"
            value={brandURL}
            onChangeText={setBrandURL}
          />
        </View>

        {/* Terms of Use Checkbox */}
        <View style={styles.termsContainer}>
          <TouchableOpacity onPress={() => setTermsAccepted(!termsAccepted)} style={styles.checkbox}>
            <Ionicons
              name={termsAccepted ? 'checkbox-outline' : 'square-outline'}
              size={24}
              color="black"
            />
          </TouchableOpacity>
          <Text style={styles.termsText}>
            By uploading this offer I agree with{' '}
            <Text style={styles.linkText} onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}>
              Collabbay terms of use
            </Text>.
          </Text>
        </View>

        <TouchableOpacity style={styles.button} onPress={handleSubmit}>
          <Text style={styles.buttonText}>Post Offer</Text>
        </TouchableOpacity>

        <Text style={styles.sectionPostDescription}>You'll receive email notifications on booking proposals, keep an eye out!</Text>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: 'white',
    padding: 20,
    paddingBottom: 40,
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 27.5,
    color: 'black',
  },
  subHeader: {
    fontSize: 16,
    textAlign: 'center',
    color: 'grey',
    marginBottom: 20,
  },
  sectionHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    color: 'black',
    marginTop: 20,
  },
  sectionDescription: {
    fontSize: 14,
    color: 'grey',
    marginBottom: 10,
  },
  sectionPostDescription: {
    fontSize: 12,
    color: 'black',
    marginBottom: 10,
    textAlign: 'center',
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginBottom: 15,
  },
  icon: {
    marginRight: 10,
  },
  input: {
    flex: 1,
    height: 40,
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
    paddingHorizontal: 10,
  },
  button: {
    backgroundColor: '#FDF05D',
    borderRadius: 20,
    paddingVertical: 15,
    alignItems: 'center',
    marginTop: 20,
  },
  buttonText: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 16,
  },
  backButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
  termsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
    marginTop: 10,
  },
  checkbox: {
    marginRight: 10,
  },
  termsText: {
    fontSize: 12,
    color: 'black',
  },
  linkText: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
});
