import React, { useEffect, useState } from 'react';
import { SafeAreaView, Alert, StyleSheet, View, Text, ScrollView, Image, TouchableOpacity, Modal, Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Linking } from 'react-native';

const App = ({ navigation }) => {
  const [userData, setUserData] = useState({});
  const [collabs, setCollabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showTutorial, setShowTutorial] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const tutorialSteps = [
    {
      icon: null,
      message: 'Welcome to collabbay. A platform that connects brands and influencers, allowing you to offer in-store credit, goods, or services in exchange for promotional influencer visits.',
    },
    {
      icon: 'add',
      message: 'Firstly you create a collab-offer, this can be a budget, good, or experience that influencers redeem in exchange for visiting and posting your venue.',
    },
    {
      icon: 'laptop',
      message: "Manage your offer directly from collabbay, track how many influencers have booked to visit, approve influencers you like, and view collabs in one dashboard. You'll be notified on each influencer booking.",
    },
    {
      icon: 'calendar',
      message: 'View and manage all upcoming influencer promotional visits on your calendar, prepare and manage scheduling. Whether you want to approve a single visit per month, or focus on a high volume of visits, manage it all here.',
    },
    {
      icon: 'person',
      message: 'Add a logo to your brand profile, this will be visible to influencers on your offer-listings.',
    },
    {
      icon: null,
      message: 'Welcome to Collabbay! If you are a new signup, access your free trial via our email link, you will need to be on trial or subscribed to access and use collabbay Business features.',
    },
  ];

  useEffect(() => {
    fetchUserData();
    fetchCollabData();
  }, []);

  const fetchUserData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetSessionData.php')
      .then(response => response.json())
      .then(data => {
        setUserData(data);
        if (data.HasSeenTutorial === 0) {
          setShowTutorial(true);
        }
      })
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const fetchCollabData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetCollabCalendar.php', {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          setCollabs([]);
        } else {
          setCollabs(data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  const handleRedoTutorial = () => {
    fetch('https://collabbayadmin.com/APIControllers/RedoTutorial.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.Status === 'Success') {
          console.log('Tutorial reset successfully.');
          // Show the tutorial again and reset to the first step
          setShowTutorial(true);
          setCurrentStep(0);
          // Reload the page or refresh the data
          navigation.reset({
            index: 0,
            routes: [{ name: 'BusinessHomepage' }], // or your main screen name
          });
        } else {
          console.error('Error resetting tutorial status: ', data.Message);
        }
      })
      .catch(error => {
        console.error('Error resetting tutorial status: ', error);
      });
  };
  
  

  const renderTutorialButton = () => (
    <TouchableOpacity style={styles.redoButton} onPress={handleRedoTutorial}>
      <Ionicons name="book" size={20} color="black" />
    </TouchableOpacity>
  );
  
  

  const handleNextStep = () => {
    if (currentStep < tutorialSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      updateHasSeenTutorial();
      setShowTutorial(false);
    }
  };

  const updateHasSeenTutorial = () => {
    fetch('https://collabbayadmin.com/APIControllers/UpdateHasSeenTutorial.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserID: userData.UserID,
        HasSeenTutorial: 1,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.Status !== 'Success') {
          console.error('Error updating tutorial status: ', data.Message);
        }
      })
      .catch(error => {
        console.error('Error updating tutorial status: ', error);
      });
  };

  const renderTutorial = () => {
    if (!showTutorial || currentStep >= tutorialSteps.length) {
      return null;
    }

    const step = tutorialSteps[currentStep];

    return (
      <Modal transparent visible={showTutorial} animationType="slide">
        <View style={styles.modalOverlay}>
          <View style={styles.tutorialCard}>
            {step.icon && (
              <Ionicons name={step.icon} size={60} color="black" style={styles.iconLarge} />
            )}
            <Text style={styles.tutorialText}>{step.message}</Text>
            <Pressable style={styles.nextButton} onPress={handleNextStep}>
              <Text style={styles.buttonText}>{currentStep < tutorialSteps.length - 1 ? 'Next' : 'Finish'}</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  const handleQRCodeScan = () => {
    Linking.openURL('https://collabbayadmin.com/QR.html');
  };

  const handleCardPress = (collabID) => {
    navigation.navigate('SingleCollabMetrics', { CollabID: collabID });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${hours}:${minutes}${ampm}`;
    const daysFromToday = Math.ceil((date - new Date()) / (1000 * 60 * 60 * 24));
    const dayCountText = daysFromToday === 0 ? 'today' : daysFromToday > 0 ? `in ${daysFromToday} days` : `${Math.abs(daysFromToday)} days ago`;
    return `${formattedDate} (${dayCountText})`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return (
    <SafeAreaView style={styles.container}>
         {renderTutorialButton()}
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <View>
          <View style={styles.bannerContainer}>
            <Image
              style={styles.bannerImage}
              source={{ uri: 'https://collabbayadmin.com/CollabbayLogoDark.png' }}
              resizeMode="contain"
            />
          </View>

          <View style={styles.bottomBackground}>
            {userData.Username ? (
              <View style={styles.header}>
                <Text style={styles.headerText}>Hi, {userData.Username}</Text>
              </View>
            ) : (
              <View style={styles.header}>
                <TouchableOpacity onPress={() => navigation.navigate('LogInPage')}>
                  <Text style={styles.headerText}>Log in</Text>
                </TouchableOpacity>
              </View>
            )}

            {/* Dashboard Section */}
            <View style={styles.section}>
              <View style={styles.cardRow}>
                <TouchableOpacity style={styles.card} onPress={() => navigation.navigate('BrandListingsDashboard')}>
                  <Ionicons name="laptop" size={35} color="white" />
                  <Text style={styles.cardTitle}>Campaigns</Text>
                  <Text style={styles.cardSubText}>Go to Dashboard</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.card} onPress={handleQRCodeScan}>
                  <Ionicons name="scan" size={35} color="white" />
                  <Text style={styles.cardTitle}>QR Scan</Text>
                  <Text style={styles.cardSubText}>Open Scanner</Text>
                </TouchableOpacity>
              </View>
            </View>

            {/* Upcoming Collabs Section */}
            {userData.Username && (
              <>
                <Text style={styles.sectionHeader}>Collab Calendar</Text>
                <View style={styles.section}>
                  {loading ? (
                    <Text></Text>
                  ) : (
                    collabs.length === 0 ? (
                      <Text style={styles.noCollabText}>No Upcoming Collabs</Text>
                    ) : (
                      collabs.slice(0, 3).map((collab, index) => (
                        <TouchableOpacity key={index} style={styles.collabCard} onPress={() => handleCardPress(collab.CollabID)}>
                          <Ionicons name="calendar" size={24} color="black" style={styles.icon} />
                          <Text style={styles.collabCardTitle}>{formatDate(collab.CreditAvailableStart)}</Text>
                          <Text style={styles.collabCardSubTitle}>Status: {collab.Status}</Text>
                          <Text style={styles.collabCardSubTitle}>Credits: £{collab.Credits}</Text>
                          <Text style={styles.collabCardSubTitle}>Promoter: {collab.Promoter}</Text>
                        </TouchableOpacity>
                      ))
                    )
                  )}
                </View>
              </>
            )}
          </View>
        </View>

        <TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}>
          <Text style={styles.linkText}> By using collabbay, you agree to our Privacy Policy and Terms of Use. </Text>
        </TouchableOpacity>

        {renderTutorial()}
      </ScrollView>

      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={handleQRCodeScan}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>

        {userData.Username ? (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessAccountPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('LogInPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        )}

        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessHomepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
          <Ionicons name="calendar" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CreateBrandListingChoice')}>
          <Ionicons name="add" size={24} color="black" />
        </TouchableOpacity>
      </View>
      
      {renderTutorial()}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    width: '100%',
  },
  contentContainer: {
    paddingHorizontal: 10,
    alignItems: 'center',
    width: '95%',
    alignSelf: 'center',
    marginTop: '1%',
  },
  section: {
    marginBottom: 20,
  },
  headerText: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    marginTop: 10,
    alignSelf: 'center',
    padding: 10,
  },
  loginText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
  },
  bannerContainer: {
    marginBottom: 20,
    borderRadius: 10,
    marginTop: 30,
    backgroundColor: 'black',
    width: '100%',
    height: 150,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
  cardRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 15,
  },
  card: {
    width: '45%',
    height: 150,
    backgroundColor: 'black',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    position: 'relative',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'white',
    textAlign: 'center',
  },
  cardSubText: {
    fontSize: 12,
    textAlign: 'center',
    color: 'white',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffff',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
  cornerTopLeft: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 10,
    height: 10,
    borderTopLeftRadius: 10,
  },
  cornerTopRight: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 10,
    height: 10,
    borderTopRightRadius: 10,
  },
  cornerBottomLeft: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 10,
    height: 10,
    borderBottomLeftRadius: 10,
  },
  cornerBottomRight: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 10,
    height: 10,
    borderBottomRightRadius: 10,
  },
  bottomBackground: {
    backgroundColor: '#FDF05D',
    borderRadius: 30,
    paddingHorizontal: 10,
    paddingBottom: 60,
  },
  linkText: {
    fontSize: 9,
    color: 'black',
    textDecorationLine: 'underline',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  sectionHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginVertical: 10,
    alignSelf: 'center',
  },
  collabCard: {
    width: '90%',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10,
    marginVertical: 5,
    alignSelf: 'center',
  },
  collabCardTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'black',
  },
  collabCardSubTitle: {
    fontSize: 12,
    color: 'black',
  },
  noCollabText: {
    fontSize: 9,
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tutorialCard: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    width: '80%',
  },
  iconLarge: {
    marginBottom: 15,
  },
  tutorialText: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 10,
  },
  nextButton: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#000',
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  redoButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: '#FDF05D', 
    padding: 6,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10, // Ensure it's above other elements
  },
});

export default App;
