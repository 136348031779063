import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, StyleSheet, FlatList, Dimensions, Button, Alert } from 'react-native';
import axios from 'axios';
import { LineChart, PieChart } from 'react-native-chart-kit';

// Get screen width
const screenWidth = Dimensions.get('window').width;

export default function UserAnalyticsDashboard() {
  const [analyticsData, setAnalyticsData] = useState(null);

  useEffect(() => {
    // Fetch data from the PHP API endpoint
    axios.get('https://collabbayadmin.com/APIControllers/collabbayAnalytics.php')
      .then((response) => {
        setAnalyticsData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Handle onboarding
  const handleOnboard = (username) => {
    axios.post('https://collabbayadmin.com/APIControllers/AdminUpdateUser.php', {
      username: username,
      updateType: 'onboard'
    })
      .then(() => {
        Alert.alert("User onboarded successfully");
        // Refresh the data after the update
        axios.get('https://collabbayadmin.com/APIControllers/collabbayAnalytics.php')
          .then((response) => setAnalyticsData(response.data));
      })
      .catch(error => console.error(error));
  };

  // Handle marking as Public Figure
  const handlePublicFigure = (username) => {
    axios.post('https://collabbayadmin.com/APIControllers/updateUser.php', {
      username: username,
      updateType: 'publicFigure'
    })
      .then(() => {
        Alert.alert("User marked as Public Figure successfully");
        // Refresh the data after the update
        axios.get('https://collabbayadmin.com/APIControllers/collabbayAnalytics.php')
          .then((response) => setAnalyticsData(response.data));
      })
      .catch(error => console.error(error));
  };

  if (!analyticsData) {
    return (
      <View style={styles.container}>
        <Text>Loading data...</Text>
      </View>
    );
  }

  // Extract metrics from data
  const {
    totalUsers,
    promoters,
    brands,
    onboardedUsers,
    newUsers,
    noProfileImageCount,
    cumulativeUsers,
    dailyUsers,
    totalCollabs,
    upcomingCollabs,
    pastCollabs,
    collabsByBrand,
    newUserList
  } = analyticsData;

  // Prepare data for the cumulative and daily user charts
  const cumulativeLabels = cumulativeUsers.map((item) => item.date);
  const cumulativeCounts = cumulativeUsers.map((item) => item.count);
  const dailyLabels = dailyUsers.map((item) => item.date);
  const dailyCounts = dailyUsers.map((item) => item.count);

  // Data for onboarded vs new users pie chart
  const pieChartData = [
    { name: 'Onboarded', count: onboardedUsers, color: '#FDF05D', legendFontColor: '#333', legendFontSize: 15 },
    { name: 'New', count: newUsers, color: '#000', legendFontColor: '#333', legendFontSize: 15 }
  ];

  return (
    <ScrollView style={styles.container}>
      <Text style={styles.title}>User Analytics Dashboard</Text>

      {/* Metrics Section */}
      <View style={styles.metricsContainer}>
        <Text style={styles.metric}>Total Users: {totalUsers}</Text>
        <Text style={styles.metric}>Number of Promoters: {promoters}</Text>
        <Text style={styles.metric}>Number of Brands: {brands}</Text>
        <Text style={styles.metric}>Onboarded Users: {onboardedUsers}</Text>
        <Text style={styles.metric}>New Users: {newUsers}</Text>
        <Text style={styles.metric}>Profiles Without Profile Images: {noProfileImageCount}</Text>
      </View>

      {/* Onboarded vs New Users Pie Chart */}
      <Text style={styles.chartTitle}>Onboarded vs New Users</Text>
      <PieChart
        data={pieChartData}
        width={screenWidth - 30}
        height={220}
        chartConfig={{
          backgroundColor: '#ffffff',
          color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
        }}
        accessor="count"
        backgroundColor="transparent"
        paddingLeft="15"
        absolute
      />

      {/* Cumulative Users Line Chart */}
      <Text style={styles.chartTitle}>Cumulative Users Over Time</Text>
      <LineChart
        data={{
          labels: cumulativeLabels,
          datasets: [
            {
              data: cumulativeCounts,
            },
          ],
        }}
        width={screenWidth - 30}
        height={220}
        yAxisLabel=""
        chartConfig={{
          backgroundColor: '#e26a00',
          backgroundGradientFrom: '#fb8c00',
          backgroundGradientTo: '#ffa726',
          decimalPlaces: 0,
          color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
          labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
          style: { borderRadius: 16 },
          propsForDots: {
            r: '6',
            strokeWidth: '2',
            stroke: '#ffa726',
          },
        }}
        bezier
        style={{ marginVertical: 8, borderRadius: 16 }}
      />

      {/* Daily Users Line Chart */}
      <Text style={styles.chartTitle}>Daily Users</Text>
      <LineChart
        data={{
          labels: dailyLabels,
          datasets: [
            {
              data: dailyCounts,
            },
          ],
        }}
        width={screenWidth - 30}
        height={220}
        yAxisLabel=""
        chartConfig={{
          backgroundColor: '#22a6b3',
          backgroundGradientFrom: '#34e7e4',
          backgroundGradientTo: '#3d3e73',
          decimalPlaces: 0,
          color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
          labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
          style: { borderRadius: 16 },
          propsForDots: {
            r: '6',
            strokeWidth: '2',
            stroke: '#22a6b3',
          },
        }}
        bezier
        style={{ marginVertical: 8, borderRadius: 16 }}
      />

      {/* Collabs Section */}
      <Text style={styles.chartTitle}>Collaborations Overview</Text>
      <View style={styles.collabContainer}>
        <Text style={styles.metric}>Total Collabs: {totalCollabs}</Text>
        <Text style={styles.metric}>Upcoming Collabs: {upcomingCollabs.length}</Text>
        <Text style={styles.metric}>Past Collabs: {pastCollabs.length}</Text>
      </View>

      {/* Enhanced Section: Collabs by Brand */}
      <Text style={styles.chartTitle}>Collaborations by Brand</Text>
      {Object.keys(collabsByBrand).map((brand) => (
        <View key={brand} style={styles.brandContainer}>
          <Text style={styles.brandName}>{brand}</Text>
          <Text style={styles.brandMetric}>Total Collabs: {collabsByBrand[brand].totalCollabs}</Text>
          <Text style={styles.brandMetric}>Past Collabs: {collabsByBrand[brand].pastCollabs}</Text>
          <Text style={styles.brandMetric}>Upcoming Collabs: {collabsByBrand[brand].upcomingCollabs}</Text>
          <Text style={styles.brandMetric}>Unique Promoters: {collabsByBrand[brand].promoters}</Text>

          {/* Check if there are upcoming collabs with CreditAvailableStart */}
          {collabsByBrand[brand].upcomingCollabs > 0 && (
            <View style={styles.upcomingSection}>
              <Text style={styles.upcomingTitle}>Upcoming Collaboration Details:</Text>
              <FlatList
                data={upcomingCollabs.filter((item) => item.BrandName === brand)}
                keyExtractor={(item) => item.CollabID.toString()}
                renderItem={({ item }) => (
                  <View style={styles.upcomingItem}>
                    <Text style={styles.upcomingText}>Credit Available Start: {item.CreditAvailableStart}</Text>
                    <Text style={styles.upcomingText}>Ad Header: {item.AdHeader}</Text>
                  </View>
                )}
              />
            </View>
          )}
        </View>
      ))}

      {/* New Section: List of New Users */}
      <Text style={styles.chartTitle}>New Users</Text>
      <FlatList
        data={newUserList}
        keyExtractor={(item) => item.UserID.toString()}
        renderItem={({ item }) => (
          <View style={styles.userRow}>
            <Text>Username: {item.Username}</Text>
            <Text>Platform Username: {item.PlatformUsername}</Text>
            <Text>Email: {item.UserEmail}</Text>

            {/* Onboard and Public Figure Buttons */}
            {item.OnboardStatus !== 'Onboarded' && (
              <Button
                title="Onboard"
                onPress={() => handleOnboard(item.Username)}
                color="#FDF05D"
              />
            )}
            {item.Status !== 'Public Figure' && (
              <Button
                title="Public Figure"
                onPress={() => handlePublicFigure(item.Username)}
                color="#000"
              />
            )}
          </View>
        )}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, padding: 20, backgroundColor: '#f4f4f9' },
  title: { fontSize: 24, fontWeight: 'bold', textAlign: 'center', marginBottom: 20 },
  metricsContainer: { marginBottom: 20 },
  metric: { fontSize: 16, marginBottom: 10 },
  chartTitle: { fontSize: 18, fontWeight: 'bold', textAlign: 'center', marginVertical: 10 },
  userRow: { padding: 10, borderBottomWidth: 1, borderColor: '#ccc' },
  collabContainer: { marginVertical: 20, padding: 10, backgroundColor: '#fff', borderRadius: 10, shadowColor: '#000', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.1 },
  brandContainer: { marginBottom: 20, padding: 15, backgroundColor: '#ffffff', borderRadius: 10, shadowColor: '#000', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.1 },
  brandName: { fontSize: 20, fontWeight: 'bold', marginBottom: 10 },
  brandMetric: { fontSize: 16, marginBottom: 5 },
  upcomingSection: { marginTop: 10 },
  upcomingTitle: { fontSize: 16, fontWeight: 'bold', marginBottom: 5, color: '#27ae60' },
  upcomingItem: { marginBottom: 10 },
  upcomingText: { fontSize: 14, color: '#34495e' },
});
